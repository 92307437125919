import ComplexTable, {
  ComplexTableCell,
  ComplexTableHeader,
  ComplexTableProps,
  ComplexTableRow,
  ComplexTableRowRendererProps,
  ComplexTableSortableCell,
} from '@/components/ComplexTable';
import { ReactNode, forwardRef, useCallback } from 'react';
import { Validation, getProperty, minMax } from '@/utils';

import ActionsDropdown from '@/components/ActionsDropdown';
import AirportDropdown from '@/components/AirportDropdown';
import { BufferTime } from '@/models/gen/graphql';
import { Button } from 'react-bootstrap';
import { ComplexFormItem } from '@/hooks/useComplexForm';
import { FullTimeString } from '@/components/TimePicker';
import ImportContractProviderDropdown from '@/components/ImportContractProviderDropdown';
import LocationDropdown from '@/components/LocationDropdown';
import LocationQueryDropdown from '@/components/LocationDropdown';
import NumberInput from '@/components/NumberInput';
import TimePickerRange from '@/components/TimePickerRange';
import useConfirmation from '@/hooks/useConfirmation';

const COLUMN_SIZES = [
  '1fr', // Airline
  '0.5fr', // Airport Code
  '1.5fr', // Start Location
  '1.5fr', // End Location
  '1fr', // Start/End Time
  '0.75fr', // Buffer Time
  '4rem', // Actions
];

export type BufferTimesTableProps = Omit<ComplexTableProps, 'columns' | 'header' | 'row'> & {};

export type BufferTimeMetadata = {
  id: string;
  isDirty: boolean;
  isValid: boolean;
  validity: {
    contractId: boolean;
    startLocationId: boolean;
    startTime: boolean;
    endTime: boolean;
    bufferMinutes: boolean;
  };
};
const getMetadata = (item: BufferTime, { update }: { update: Record<string, unknown> }): BufferTimeMetadata => {
  const validity = {
    contractId: !!item?.contractId, //Validation.isValidUUID(item?.contractId),
    airportCode: !!item?.airportCode, //Validation.isValidUUID(item?.airportCode),
    startLocationId: !!item?.startLocationId, //Validation.isValidUUID(item?.contractId),
    startTime: !!item?.startTime, //Validation.isFullTimeString(item?.startTime),
    endTime: !!item?.endTime, //Validation.isFullTimeString(item?.endTime),
    bufferMinutes: Validation.isNumber(item?.bufferMinutes) && Validation.isWithinThreshold(item?.bufferMinutes, -1440, 0), // buffer time should be negative
  };
  return {
    id: item?.id,
    isDirty: !!update,
    isValid: !item || !Object.values(validity).includes(false),
    validity,
  };
};

const BufferTimesTable = (props: BufferTimesTableProps, ref): ReactNode => {
  const sorting = useCallback((items: ComplexFormItem<BufferTime>[], col: number, dir: 'asc' | 'desc'): ComplexFormItem<BufferTime>[] => {
    if (col === undefined) return items;
    const column = [
      'data.provider.name', // Airline
      'data.airportCode', // Airport Code
      'data.startLocation.name', // Start Location
      'data.endLocation.name', // End Location
      'data.startTime', // Start/End Time
      'data.bufferMinutes', // Buffer Time
    ][col];
    return items.sort(
      (a: ComplexFormItem<BufferTime>, b: ComplexFormItem<BufferTime>): number =>
        (getProperty(column, a) < getProperty(column, b) ? -1 : getProperty(column, a) > getProperty(column, b) ? 1 : 0) *
        (dir === 'desc' ? -1 : 1)
    );
  }, []);

  return (
    <ComplexTable
      {...props}
      columns={COLUMN_SIZES}
      header={BufferTimesTableHeader}
      row={BufferTimesTableRow}
      metadata={getMetadata}
      options={{ ...(props?.options || {}), sorting }}
      style={{ maxHeight: '60vh' }}
      ref={ref}
    />
  );
};

const BufferTimesTableHeader = (): ReactNode => {
  return (
    <ComplexTableHeader>
      <ComplexTableSortableCell index={0}>Airline</ComplexTableSortableCell>
      <ComplexTableSortableCell index={1}>Airport</ComplexTableSortableCell>
      <ComplexTableSortableCell index={2}>Start Location</ComplexTableSortableCell>
      <ComplexTableSortableCell index={3}>End Location</ComplexTableSortableCell>
      <ComplexTableSortableCell index={4}>Start/End Time</ComplexTableSortableCell>
      <ComplexTableSortableCell index={5}>Buffer Time</ComplexTableSortableCell>
      <ComplexTableCell className="text-center">
        <Button variant="icon" className="{cursor:normal;}">
          <i className="fa fa-ellipsis-h fs-5" />
        </Button>
      </ComplexTableCell>
    </ComplexTableHeader>
  );
};

const BufferTimesTableRow = ({ index, item, data, getValue, onChange, onDelete }: ComplexTableRowRendererProps): ReactNode => {
  const { validity } = item;
  const contractId = getValue('contractId');
  const providerDisplayName = getValue('provider.name');
  const airportCode = getValue('airportCode');
  const startLocationId = getValue('startLocationId');
  const startLocationDisplayName = getValue('startLocation.name');
  const endLocationId = getValue('endLocationId');
  const endLocationDisplayName = getValue('endLocation.name');
  const startTime = getValue('startTime');
  const endTime = getValue('endTime');
  const bufferMinutes = getValue('bufferMinutes');
  const isAddition = !item?.data?.id;

  const confirmDelete = useConfirmation({
    Body: { title: 'Delete Buffer Time?', message: 'Are you sure you want to delete this buffer time?' },
  });

  const handleDelete = async (): Promise<void> => {
    try {
      await confirmDelete();
      await onDelete();
      console.log('Delete Confirmed', item);
    } catch (err) {
      console.log('Delete Cancelled');
    }
  };

  return (
    <ComplexTableRow>
      <ComplexTableCell>
        {!isAddition && !!data?.contractId ? (
          providerDisplayName || '--'
        ) : (
          <ImportContractProviderDropdown
            name="contractId"
            value={contractId}
            onChange={onChange('contractId')}
            options={{ locale: { 'Select...': 'Airline' } }}
            isValid={validity?.contractId === true}
            isInvalid={validity?.contractId === false}
          />
        )}
      </ComplexTableCell>
      <ComplexTableCell>
        {!isAddition && !!data?.airportCode ? (
          airportCode || '--'
        ) : (
          <AirportDropdown
            name="airportCode"
            value={airportCode}
            onChange={onChange('airportCode')}
            placeholder="Airport"
            isValid={validity?.airportCode === true}
            isInvalid={validity?.airportCode === false}
          />
        )}
      </ComplexTableCell>
      <ComplexTableCell>
        {!isAddition && !!data?.startLocationId ? (
          startLocationDisplayName || '--'
        ) : (
          <LocationQueryDropdown
            name="startLocationId"
            value={startLocationId}
            onChange={onChange('startLocationId')}
            options={{ locale: { 'Select...': 'Start Location' }, showAirportPrefix: false }}
            isValid={validity?.startLocationId === true}
            isInvalid={validity?.startLocationId === false}
            airports={airportCode ? [airportCode] : []}
          />
        )}
      </ComplexTableCell>
      <ComplexTableCell>
        {!isAddition ? (
          endLocationDisplayName || '--'
        ) : (
          <LocationDropdown
            name="endLocationId"
            value={endLocationId}
            onChange={onChange('endLocationId')}
            options={{ locale: { 'Select...': 'End Location' }, showAirportPrefix: false }}
            airports={airportCode ? [airportCode] : []}
          />
        )}
      </ComplexTableCell>
      <ComplexTableCell>
        <TimePickerRange
          name="range"
          value={[startTime || null, endTime || null]}
          onChange={(value: FullTimeString[]) => {
            onChange('startTime')(value[0]);
            onChange('endTime')(value[1]);
          }}
          isDirty={!isAddition && (startTime !== data?.startTime || endTime !== data?.endTime)}
          isValid={isAddition && validity?.startTime === true && validity?.endTime === true}
          isInvalid={validity?.startTime === false || validity?.endTime === false}
        />
      </ComplexTableCell>
      <ComplexTableCell>
        <NumberInput
          name="bufferMinutes"
          value={Math.abs(bufferMinutes)}
          onChange={(value: number): void => onChange('bufferMinutes')(minMax(value, 0, 1440) * -1)} // buffer time should be sent and stored as a negative in the BE
          append={'mins'}
          isDirty={!isAddition && bufferMinutes !== data?.bufferMinutes}
          isValid={isAddition && validity?.bufferMinutes === true}
          isInvalid={validity?.bufferMinutes === false}
        />
      </ComplexTableCell>
      <ComplexTableCell className="text-center">
        <ActionsDropdown
          icon={<i className="fa fa-ellipsis-h fs-5" />}
          items={[{ key: index, label: 'DELETE' }]}
          className="ActionsCellDropdown text-center"
          onClick={handleDelete}
        />
      </ComplexTableCell>
    </ComplexTableRow>
  );
};

export default forwardRef(BufferTimesTable);
