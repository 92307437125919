import { GetAvailableUserForTripResponse, SearchTypeEnum, User } from '@/models/gen/graphql';
import QueryDropdown, { QueryDropdownProps } from '@/components/QueryDropdown';
import { ReactNode, useCallback } from 'react';

import { DropdownItem } from '@/components/Dropdown';
import getAvailableUsersForTrip from '@/api/services/trips/getAvailableUsersForTrip';
import { getSelectUserFilter } from '@/api/services/users/searchUsers';
import md5 from 'md5';

export type DriverDropdownProps = Omit<QueryDropdownProps, 'query'> & {
  airports?: string[];
};

const DriverDropdown = ({ name = 'driver', airports = [], ...props }: DriverDropdownProps): ReactNode => {
  const getItems = useCallback(async (): Promise<DropdownItem[]> => {
    const res = await getSelectUserFilter(airports, {
      roleId: [{ type: SearchTypeEnum.Or, values: ['634cdbdb-0c78-11ed-82d4-0242ac190003'] }],
    });
    return res?.map?.((user: User): DropdownItem => ({ label: user.fullName, value: user.id, keywords: user.fullName.split(' ') })) || [];
  }, [airports]);

  return (
    <QueryDropdown
      {...props}
      name={`${name}_${airports.join('|') || 'ALL'}`}
      query={getItems}
      options={{ ...(props?.options || {}), locale: { ...(props?.options?.locale || {}), 'Select...': 'Driver' } }}
    />
  );
};

export type AssignDriverDropdownProps = Omit<QueryDropdownProps, 'query'> & {
  airportCode: string;
  scheduled: string;
  actual: string;
  details: User;
};

export const AssignDriverDropdown = ({
  name = 'driver',
  airportCode,
  scheduled,
  actual,
  details,
  ...props
}: AssignDriverDropdownProps): ReactNode => {
  const getItems = useCallback(async (): Promise<DropdownItem[]> => {
    const res = await getAvailableUsersForTrip({ airportCode, scheduled, actual });
    const result =
      res.map?.(
        (user: GetAvailableUserForTripResponse): DropdownItem => ({
          label: [user.firstName, user.lastName].join(' '),
          value: user.userId,
          keywords: [user.firstName, user.lastName],
        })
      ) || [];
    if (details) {
      return [
        {
          label: [details.firstName, details.lastName].join(' '),
          value: details?.id,
          keywords: [details.firstName, details.lastName],
        },
        ...result.filter((item: DropdownItem): boolean => item.value !== details?.id),
      ];
    }
    return result;
  }, [airportCode, scheduled, actual, details]);

  return (
    <QueryDropdown
      {...props}
      name={`${name}_${md5(airportCode + scheduled + actual)}`}
      query={getItems}
      options={{ ...(props.options || {}), locale: { 'No Options': 'No Available Drivers', ...(props.options?.locale || {}) } }}
    />
  );
};

export default DriverDropdown;
