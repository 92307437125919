import ComplexTable, {
  ComplexTableCell,
  ComplexTableHeader,
  ComplexTableProps,
  ComplexTableRow,
  ComplexTableRowRendererProps,
  ComplexTableSortableCell,
} from '@/components/ComplexTable';
import { ReactNode, forwardRef, useCallback } from 'react';
import { Validation, getProperty, minMax } from '@/utils';

import ActionsDropdown from '@/components/ActionsDropdown';
import AirlineIataDropdown from '@/components/AirlineDropdown';
import AirportDropdown from '@/components/AirportDropdown';
import { Button } from 'react-bootstrap';
import { ComplexFormItem } from '@/hooks/useComplexForm';
import NumberInput from '@/components/NumberInput';
import { VariableTime } from '@/models/gen/graphql';
import useConfirmation from '@/hooks/useConfirmation';

const COLUMN_SIZES = [
  '1.5fr', // Airline
  '1.5fr', // Airport Code
  '0.75fr', // Inbound Domestic
  '0.75fr', // Inbound International
  '0.75fr', // Outbound Cushion
  '4rem', // Actions
];

export type VariableTimesTableProps = Omit<ComplexTableProps, 'columns' | 'header' | 'row'> & {};

export type VariableTimeMetadata = {
  id: string;
  isDirty: boolean;
  isValid: boolean;
  validity: {
    servicerIataAirlineCode: boolean;
    airportCode: boolean;
    inboundDomestic: boolean;
    inboundInternational: boolean;
    outbound: boolean;
  };
};
const getMetadata = (item: VariableTime, { update }: { update: Record<string, unknown> }): VariableTimeMetadata => {
  const validity = {
    servicerIataAirlineCode: !!item?.servicerIataAirlineCode,
    airportCode: !!item?.airportCode,
    inboundDomestic: Validation.isNumber(item?.inboundDomestic) ? Validation.isWithinThreshold(item?.inboundDomestic, 0, 1440) : undefined,
    inboundInternational: Validation.isNumber(item?.inboundInternational)
      ? Validation.isWithinThreshold(item?.inboundDomestic, 0, 1440)
      : undefined,
    outbound: Validation.isNumber(item?.outbound) ? Validation.isWithinThreshold(item?.inboundDomestic, 0, 1440) : undefined,
  };
  return {
    id: item?.id,
    isDirty: !!update,
    isValid: !item || !Object.values(validity).includes(false),
    validity,
  };
};

const VariableTimesTable = (props: VariableTimesTableProps, ref): ReactNode => {
  const sorting = useCallback(
    (items: ComplexFormItem<VariableTime>[], col: number, dir: 'asc' | 'desc'): ComplexFormItem<VariableTime>[] => {
      if (col === undefined) return items;
      const column = [
        'data.servicerIataAirlineCode', // Airline
        'data.airportCode', // Airport
        'data.inboundDomestic', // Inbound Domestic
        'data.inboundInternational', // Inbound International
        'data.outbound', // Outbound
      ][col];
      return items.sort(
        (a: ComplexFormItem<VariableTime>, b: ComplexFormItem<VariableTime>): number =>
          (getProperty(column, a) < getProperty(column, b) ? -1 : getProperty(column, a) > getProperty(column, b) ? 1 : 0) *
          (dir === 'desc' ? -1 : 1)
      );
    },
    []
  );

  return (
    <ComplexTable
      {...props}
      columns={COLUMN_SIZES}
      header={VariableTimesTableHeader}
      row={VariableTimesTableRow}
      metadata={getMetadata}
      options={{ ...(props?.options || {}), sorting }}
      style={{ maxHeight: '60vh' }}
      ref={ref}
    />
  );
};

const VariableTimesTableHeader = (): ReactNode => (
  <ComplexTableHeader>
    <ComplexTableSortableCell index={0}>Airline</ComplexTableSortableCell>
    <ComplexTableSortableCell index={1}>Airport</ComplexTableSortableCell>
    <ComplexTableSortableCell index={2}>Inbound Domestic VT</ComplexTableSortableCell>
    <ComplexTableSortableCell index={3}>Inbound INT&apos;L VT</ComplexTableSortableCell>
    <ComplexTableSortableCell index={4}>Outbound Cushion</ComplexTableSortableCell>
    <ComplexTableCell className="text-center">
      <Button variant="icon" className="{cursor:normal;}">
        <i className="fa fa-ellipsis-h fs-5" />
      </Button>
    </ComplexTableCell>
  </ComplexTableHeader>
);

const VariableTimesTableRow = ({ index, item, data, getValue, onChange, onDelete }: ComplexTableRowRendererProps): ReactNode => {
  const { validity } = item;
  const servicerIataAirlineCode = getValue('servicerIataAirlineCode');
  const airportCode = getValue('airportCode');
  const inboundDomestic = getValue('inboundDomestic');
  const inboundInternational = getValue('inboundInternational');
  const outbound = getValue('outbound');
  const isAddition = !item?.data?.id;

  const confirmDelete = useConfirmation({
    Body: { title: 'Delete Variable Time?', message: 'Are you sure you want to delete this variable time?' },
  });

  const handleDelete = async (): Promise<void> => {
    try {
      await confirmDelete();
      await onDelete();
      console.log('Delete Confirmed', item);
    } catch (err) {
      console.log('Delete Cancelled');
    }
  };

  return (
    <ComplexTableRow>
      <ComplexTableCell>
        {!isAddition && !!data?.servicerIataAirlineCode ? (
          servicerIataAirlineCode || '--'
        ) : (
          <AirlineIataDropdown
            name="servicerIataAirlineCode"
            value={servicerIataAirlineCode}
            onChange={onChange('servicerIataAirlineCode')}
            isValid={validity?.servicerIataAirlineCode === true}
            isInvalid={validity?.servicerIataAirlineCode === false}
          />
        )}
      </ComplexTableCell>
      <ComplexTableCell>
        {!isAddition && !!data?.airportCode ? (
          airportCode || '--'
        ) : (
          <AirportDropdown
            name="airportCode"
            value={airportCode}
            onChange={onChange('airportCode')}
            options={{ locale: { 'Select...': 'Airport' } }}
            isValid={validity?.airportCode === true}
            isInvalid={validity?.airportCode === false}
          />
        )}
      </ComplexTableCell>
      <ComplexTableCell>
        <NumberInput
          name="inboundDomestic"
          value={inboundDomestic}
          onChange={(value: number): void => onChange('inboundDomestic')(minMax(value, 0, 1440))}
          append={'mins'}
          placeholder="--"
          isDirty={!isAddition && inboundDomestic !== (data?.inboundDomestic ?? '')}
          isValid={isAddition && validity?.inboundDomestic === true}
          isInvalid={validity?.inboundDomestic === false}
        />
      </ComplexTableCell>
      <ComplexTableCell>
        <NumberInput
          name="inboundInternational"
          value={inboundInternational}
          onChange={(value: number): void => onChange('inboundInternational')(minMax(value, 0, 1440))}
          append={'mins'}
          placeholder="--"
          isDirty={!isAddition && inboundInternational !== (data?.inboundInternational ?? '')}
          isValid={isAddition && validity?.inboundInternational === true}
          isInvalid={validity?.inboundInternational === false}
        />
      </ComplexTableCell>
      <ComplexTableCell>
        <NumberInput
          name="outbound"
          value={outbound}
          onChange={(value: number): void => onChange('outbound')(minMax(value, 0, 1440))}
          append={'mins'}
          placeholder="--"
          isDirty={!isAddition && outbound !== (data?.outbound ?? '')}
          isValid={isAddition && validity?.outbound === true}
          isInvalid={validity?.outbound === false}
        />
      </ComplexTableCell>
      <ComplexTableCell className="text-center">
        <ActionsDropdown
          icon={<i className="fa fa-ellipsis-h fs-5" />}
          items={[{ key: index, label: 'DELETE', danger: true }]}
          className="ActionsCellDropdown text-center"
          onClick={handleDelete}
        />
      </ComplexTableCell>
    </ComplexTableRow>
  );
};

export default forwardRef(VariableTimesTable);
