import { useCallback, useMemo, useRef } from 'react';

import useSocket from '@/hooks/useSocket';
import { createComponentState } from '@/state';
import { createNotification, getResultFromState, stringify } from '@/utils';
import { Toast } from '@/models';
import useChatMethods from './useChatMethods';
import { useMessageCenterFilters } from '@/features/MessageCenter/components/MessageCenterFilters';

export type Message = {
  id?: string;
  content?: string;
  type?: string;
  room_id: string;
  room_display_name?: string;
  color?: string;
  sessionid?: string;
  datetime?: string;
  user_id?: string;
  role_id?: string;
  role_name?: string;
  user_profile_image_url?: string;
  user_provider_id: string;
  session_id?: string;
  username?: string;
  recipeint_read_at?: string;
  recipeint_read_by?: string;
  trip_id?: string;
  trip_scheduled?: string;
  flight_number?: number;
  servicer_iata_airline_code?: string;
};

export type Room = {
  id?: string;
  unreadCount: number;
  displayName: string;
  displayImageUrl: string;
  trip?: {
    tripId: string;
    tripScheduled: string;
    tripFlightNumber: string | number;
    servicerIataAirlineCode: string;
  };
  roomType?: ChatRoomType;
  latestMessage: Message;
  latestMessagePerRole?: Record<string, Message>;
};

export type ResponseRoom = {
  id: string;
  unread_count: number;
  unreplied_count: number;
  display_name: string;
  display_image_url: string;
  trip_id: string;
  trip_scheduled: string;
  flight_number: string | number;
  servicer_iata_airline_code: string;
  latest_message: Message;
};

export type RoomPayload = {
  session_id: string;
  role_id: string;
  rooms: ResponseRoom[];
  total_unread_messages: number;
  room_id: string;
  format: ChatRoomType;
};
export type RoomRequest = {
  format?: ChatRoomType;
};
export type RoomsState = Record<ChatRoomType, Record<string, Room>>;
export type UseChatRoomsReturn = [
  RoomsState,
  setRooms: (rooms: RoomsState) => void,
  {
    getRooms: (payload: any) => void;
    totalUnread: number;
    sendMessage: (payload?: Record<string, unknown>) => void;
    clearUserTyping: (userId: string) => void;
  },
];
export enum ChatRoomType {
  ALL_CHAT = 'ALL_CHAT',
  CURRENT_CHAT = 'CURRENT_CHAT',
  MY_CHAT = 'MY_CHAT',
}

export const useChatState = createComponentState<{
  sessionId: string;
  roomId: string;
  totalUnread: number;
  rooms: RoomsState;
  usersTyping: any;
  messages: Message[];
  history: Message[];
}>({
  sessionId: undefined,
  roomId: undefined,
  totalUnread: 0,
  rooms: {
    ALL_CHAT: {},
    CURRENT_CHAT: {},
    MY_CHAT: {},
  },
  usersTyping: [],
  messages: [],
  history: [],
});

// TODO: Maybe find a better place for this?
export const useSelectedRoomState = createComponentState<{ room: Room }>({
  room: undefined,
});

const useChatRooms = (format?: ChatRoomType): UseChatRoomsReturn => {
  const [{ flightNumber }] = useMessageCenterFilters();
  const { getRooms } = useChatMethods();
  const lastWarning = useRef(null);
  const [{ totalUnread, rooms, roomId, sessionId }, setState] = useChatState(
    ({ state: { sessionId, roomId, totalUnread, rooms }, setState }) => [{ sessionId, roomId, totalUnread, rooms }, setState]
  );

  const socket = useSocket({
    'chat.setHistory': ({ payload }) => {
      if (payload?.rooms?.[0].id !== roomId) return;
      setState((current) => ({ ...current, history: payload?.rooms?.[0].history || [] }));
    },
    'chat.setMetadata': ({ payload }: { eventType: string; payload: RoomPayload; overrides: Record<string, unknown> }) => {
      setState((current) => ({
        ...current,
        sessionId: payload?.session_id,
        roomId: payload?.room_id,
        totalUnread: payload?.total_unread_messages,
        rooms: payload?.rooms?.reduce(
          (acc, room) => {
            // Ensure that the room type is one of the ChatRoomType enum values
            if (payload?.format && ChatRoomType[payload?.format]) {
              return {
                ...acc,
                [payload?.format]: {
                  ...(acc[payload?.format] || {}),
                  [room?.id]: {
                    unreadCount: room?.unreplied_count,
                    displayName: room?.display_name || room?.id.split('::')[0] || '',
                    displayImageUrl: room?.display_image_url || null,
                    latestMessage: room?.latest_message || null,
                    trip: {
                      tripId: room?.trip_id || null,
                      tripScheduled: room?.trip_scheduled || null,
                      tripFlightNumber: room?.flight_number || null,
                      servicerIataAirlineCode: room?.servicer_iata_airline_code || null,
                    },
                    roomType: payload?.format,
                  },
                },
              };
            }
            return acc;
          },
          {
            ...current.rooms,
            [payload?.format]: {}, // Clear the rooms for the specific format before adding new ones
          } as RoomsState
        ), // Initialize with an empty object, typed as RoomsState
      }));
    },
    'chat.receiveWarn': ({ payload }) => {
      if (stringify.compare(lastWarning?.current, payload?.message)) return;
      lastWarning.current = payload?.message;
      createNotification(payload?.message, Toast.Type.DANGER, 'Chat Error');
    },
    'chat.receiveMessage': ({ payload }) => {
      // If the room doesn't exist , create a new one and add it
      if (!rooms?.[ChatRoomType.ALL_CHAT]?.[payload?.room_id]) {
        addNewRoom(payload, [ChatRoomType.ALL_CHAT]);
      }
      if (!rooms?.[ChatRoomType.CURRENT_CHAT]?.[payload?.room_id]) {
        addNewRoom(payload, [ChatRoomType.CURRENT_CHAT]);
      }
      if (sessionId === payload?.session_id && !rooms?.[ChatRoomType.MY_CHAT]?.[payload?.room_id]) {
        copyRoom(payload?.room_id, ChatRoomType.ALL_CHAT, ChatRoomType.MY_CHAT);
      }
      // Update Message Information associated to the room
      updateMessage(payload);
      // If the message is not from this room, ignore it
      if (payload.room_id !== roomId) {
        return;
      }
      // Dedupe and then append the new message to the room history
      setState((current) => {
        const updatedMessages = [payload, ...(current?.history || [])];
        const uniqueMessages = new Map(updatedMessages.map((message) => [message.id, message]));
        return { ...current, history: Array.from(uniqueMessages.values()) };
      });
      clearUserTyping(payload?.user_id);
      setState((current) => ({ ...current, messages: [...current.messages, payload] }));
    },
    'chat.receiveUserTyping': ({ payload }: { eventType: string; payload; overrides: Record<string, unknown> }) => {
      const parsedPayload = typeof payload === 'string' ? JSON.parse(payload) : payload;
      if (parsedPayload?.room_id !== roomId || parsedPayload?.session_id === sessionId) return;
      setState((current) => {
        const now = Date.now();
        let found = false;
        const result = current.usersTyping.map((user) => {
          if (user.user_id === parsedPayload.user_id && user.room_id === parsedPayload.room_id) {
            found = true;
            return { ...user, timestamp: now };
          }
          return user;
        });
        if (!found) result.push({ ...parsedPayload, timestamp: now });
        return { ...current, usersTyping: result };
      });
    },
  });
  const updateMessage = useCallback(
    (payload: Message) => {
      const isCrew = payload?.role_name?.toLocaleLowerCase() === 'crew'; // Lowercase role name check
      setState((current) => ({
        ...current,
        rooms: Object.keys(current.rooms).reduce((acc, roomType) => {
          const roomsOfType = current.rooms[roomType as ChatRoomType];

          // Check if this roomType contains the room with the given room_id
          if (roomsOfType?.[payload.room_id]) {
            const room = roomsOfType[payload.room_id];
            const unreadCount = room.latestMessage?.id === payload.id ? room.unreadCount : isCrew ? room.unreadCount + 1 : 0;
            return {
              ...acc,
              [roomType]: {
                ...roomsOfType,
                [payload.room_id]: {
                  ...room,
                  unreadCount,
                  latestMessage: payload,
                },
              },
            };
          }
          // If the room doesn't exist in this roomType, just return it as is
          return {
            ...acc,
            [roomType]: roomsOfType,
          };
        }, {} as RoomsState), // Initial value is an empty object
      }));
    },
    [setState]
  );
  const addNewRoom = useCallback(
    (payload: Message, roomTypes: ChatRoomType[]) => {
      setState((current) => ({
        ...current,
        rooms: roomTypes.reduce((acc, roomType) => {
          return {
            ...acc,
            [roomType]: {
              ...current.rooms[roomType],
              [payload?.room_id]: {
                unreadCount: 1,
                displayName: payload?.room_display_name,
                displayImageUrl: payload?.user_profile_image_url || '',
                latestMessage: payload,
                roomType,
                trip: {
                  tripId: payload?.trip_id || '',
                  tripScheduled: payload?.trip_scheduled || '',
                  tripFlightNumber: payload?.flight_number || '',
                  servicerIataAirlineCode: payload?.servicer_iata_airline_code || '',
                },
              },
            },
          };
        }, current.rooms), // Start with the existing rooms as the initial value
      }));
    },
    [setState]
  );
  const copyRoom = (roomId: string, copyFrom: ChatRoomType, copyTo: ChatRoomType) => {
    if (!roomId || !rooms[copyFrom][roomId]) return;
    setState((current) => ({
      ...current,
      rooms: {
        ...current.rooms,
        [copyTo]: {
          ...current.rooms[copyTo],
          [roomId]: { ...current.rooms[copyFrom][roomId], unreadCount: 0 },
        },
      },
    }));
  };
  const sendMessage = useCallback((payload?: Record<string, unknown>): void => socket.emit('chat.sendMessage', payload), [socket]);
  const setRooms = (rooms: RoomsState | ((current: RoomsState) => RoomsState)) =>
    setState((current) => ({ ...current, rooms: getResultFromState(rooms, current.rooms) }));

  const clearUserTyping = useCallback(
    (userId: string) => {
      setState((current) => ({
        ...current,
        usersTyping: current.usersTyping.filter((user) => user.user_id !== userId),
      }));
    },
    [setState]
  );

  const filteredRooms = useMemo(() => {
    if (flightNumber === '') {
      return rooms;
    } else {
      return Object.keys(rooms).reduce((filtered, roomType) => {
        const roomsOfType = rooms[roomType as ChatRoomType];

        // Filter through the rooms of the current room type
        const filteredRoomsOfType = Object.keys(roomsOfType).reduce(
          (acc, roomId) => {
            const room = roomsOfType[roomId];
            if (room?.trip?.tripFlightNumber === flightNumber) {
              acc[roomId] = room;
            }
            return acc;
          },
          {} as Record<string, Room>
        );

        // Only add non-empty filtered rooms to the result
        if (Object.keys(filteredRoomsOfType).length > 0) {
          filtered[roomType as ChatRoomType] = filteredRoomsOfType;
        }

        return filtered;
      }, {} as RoomsState);
    }
  }, [flightNumber, rooms]);

  return [filteredRooms, setRooms, { getRooms, totalUnread, sendMessage, clearUserTyping }];
};

export default useChatRooms;
