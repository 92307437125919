import { FilterOption, RateHistoryCurrencyEnum } from '@/models/gen/graphql';
import QueryDropdown, { QueryDropdownProps } from '@/components/QueryDropdown';
import { ReactNode, useCallback } from 'react';

import { DropdownItem } from '@/components/Dropdown';
import { getRateValues } from '@/api/services/filters/rates';

type RateValuesDropdownProps = Omit<QueryDropdownProps, 'query'>;
const RateValuesDropdown = ({ name = 'rateValues', ...props }: RateValuesDropdownProps): ReactNode => {
  const getItems = useCallback(async (): Promise<DropdownItem[]> => {
    const res = await getRateValues({ currency: RateHistoryCurrencyEnum.Usd });
    const options = res
      ?.filter((option: FilterOption): boolean => parseInt(option.values[0]) !== 0)
      ?.map?.(
        (option: FilterOption): DropdownItem => ({
          label: option.displayName,
          value: option.values[0],
          keywords: option.displayName.split(' '),
        })
      );
    if (options.length >= 0) options.unshift({ label: 'No Value', value: 'NO_RATE', keywords: ['No', 'Value'] });
    return options;
  }, []);

  return <QueryDropdown {...props} name={name} query={getItems} options={{ locale: props?.options?.locale || { 'Select...': 'Rate' } }} />;
};

export default RateValuesDropdown;
